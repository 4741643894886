import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes as faXmark } from "@fortawesome/free-solid-svg-icons";
import AdditionalNotes from "./AdditionalNotes";
import UpdateReceiptForm from "./UpdateReceiptForm";

class ReceiptList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      expandedRowID,
      expandedRowType,
      notesText,
      receiptList,
      updateNotes,
      addNote,
      updateExpandRow,
      triggerDownloadSingleReceipt,
      receiptMailed,
      title,
      receiptInfo,
      isLoading,
      attendeeError,
      updateReceipt,
      handleReceiptInfoChange
    } = this.props;

    return (
      <section className="container stylization maincont">
        <h1 className="main-ttl">
          <span>{title}</span>
        </h1>
        <div className="cart-items-wrap">
          <table className="cart-items">
            <thead>
              <tr>
                <td className="cart-ttl">ID / Receipt Number / Gift Date</td>
                <td className="cart-ttl">Mailing Address</td>
                <td className="cart-quantity">Amount / Receipt Amount</td>
                <td className="cart-ttl">Reference</td>
                <td className="cart-ttl">Notes</td>
                <td className="cart-ttl">Mailed?</td>
                <td className="cart-ttl">Mail and Reissue Dates</td>
                <td className="cart-del">&nbsp;</td>
                <td className="cart-del">&nbsp;</td>
                <td className="cart-del">&nbsp;</td>
              </tr>
            </thead>

            <tbody>
              {receiptList.map((receipt, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      [{receipt.ID}]<br />
                      {receipt.Receipt_Number}<br/>
                      {receipt.Gift_Date}
                    </td>
                    <td>
                      {receipt.Addressee}
                      <br />
                      {receipt.Organization_Name && (
                        <>
                          {receipt.Organization_Name}
                          <br />
                        </>
                      )}
                      {receipt.Address_Line_1}
                      <br />
                      {receipt.Address_Line_2 && (
                        <>
                          {receipt.Address_Line_2}
                          <br />
                        </>
                      )}
                      {receipt.City}, {receipt.Province} {receipt.Postal_Code}
                    </td>
                    <td>
                      {receipt.Amount} <br /> {receipt.Receipt_Amount}
                    </td>
                    <td>{receipt.Reference}</td>
                    <td
                      dangerouslySetInnerHTML={{ __html: receipt.AllNotes }}
                    />
                    <td>
                      {receipt.mailedDate ? (
                        <FontAwesomeIcon
                          style={{ color: "green" }}
                          icon={faCheck}
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{ color: "red" }}
                          icon={faXmark}
                        />
                      )}
                    </td>

                    <td>
                    {receipt.mailedDate && (
                      <ul>
                        {receipt.mailedDate.split(',').map((date, index) => (
                          <li key={index}>{date.trim()}</li>
                        ))}
                      </ul>
                    )}
                  </td>


                    <td className="cart-del">
                      <a
                        className="cart-edit"
                        href="/"
                        onClick={(e) => {
                          updateExpandRow(e, receipt.ID, "Receipt");
                        }}
                        role="button" // Add role="button" to indicate it's a button
                      >
                        {"\u00A0"}
                      </a>
                    </td>
                    <td className="cart-del">
                      <a
                        className="cart-notes"
                        href="/"
                        onClick={(e) => {
                          updateExpandRow(e, receipt.ID, "Note");
                        }}
                        role="button" // Add role="button" to indicate it's a button
                      >
                        {"\u00A0"}
                      </a>
                    </td>
                    <td className="cart-del">
                      <a
                        className="cart-download"
                        href="/"
                        onClick={(e) => {
                          triggerDownloadSingleReceipt(e, receipt.ID);
                        }}
                        role="button" // Add role="button" to indicate it's a button
                      >
                        {"\u00A0"}
                      </a>
                    </td>

                    <td className="cart-del">
                      <a
                        className="cart-mail"
                        href="/"
                        onClick={(e) => {
                          receiptMailed(e, receipt.ID);
                        }}
                        role="button" // Add role="button" to indicate it's a button
                      >
                        {"\u00A0"}
                      </a>
                    </td>
                  </tr>
                  {expandedRowID === receipt.ID && (
                    <>
                      <tr className="notes-row">
                        <td colSpan="9">
                          {expandedRowType === "Note" && (
                            <>
                              <AdditionalNotes
                                text={notesText}
                                handleChange={updateNotes}
                                addNote={addNote}
                                receiptID={receipt.ID}
                              />
                            </>
                          )}

                          {expandedRowType === "Receipt" && (
                            <>
                              <UpdateReceiptForm
                                    receiptInfo={receiptInfo}
                                    isLoading={isLoading}
                                    attendeeError={attendeeError}
                                    updateReceipt={updateReceipt}
                                    handleReceiptInfoChange={handleReceiptInfoChange}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default ReceiptList;
